import { Create, SimpleForm, TextInput } from 'react-admin';

const UserCreate = () => (
  <Create >
    <SimpleForm>
      <TextInput source="display_name" />
      <TextInput source="email" />
    </SimpleForm>
  </Create>
);

export default UserCreate;
