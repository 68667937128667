import { Edit, SimpleForm, TextInput, useRecordContext } from 'react-admin';

const UserTitle = () => {
  const record = useRecordContext();
  return <span>ユーザー編集: {record ? `"${record.display_name}"` : ''}</span>;
}

const UserEdit = () => (
  <Edit title={<UserTitle />}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="display_name" />
      <TextInput source="email" />
      <TextInput source="serial_code" />
    </SimpleForm>
  </Edit>
);

export default UserEdit;
