import MeditationIcon from '@mui/icons-material/ForestOutlined';

import MeditationShow from './MeditationShow';
import MeditationList from './MeditationList';
import MeditationCreate from './MeditationCreate';
import MeditationEdit from './MeditationEdit';

const resource = {
  show: MeditationShow,
  list: MeditationList,
  edit: MeditationEdit,
  create: MeditationCreate,
  icon: MeditationIcon,
};

export default resource;
