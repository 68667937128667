import React from 'react';
import { Admin, Resource } from 'react-admin';

import authProvider from './authProvider';
import dataProvider from './dataProvider';
import AdminDashboard from './components/AdminDashboard';
import users from './users';
import meditations from './meditations';
import { Layout } from './components/Layout';

const App: React.FC = () => (
  <Admin
    authProvider={authProvider}
    dataProvider={dataProvider}
    dashboard={AdminDashboard}
    layout={Layout}
  >
    <Resource name="users" {...users} />
    <Resource name="meditations" {...meditations} />
  </Admin>
);

export default App;
