import { useMediaQuery, Theme } from "@mui/material";
import { List, Datagrid, TextField, EmailField, EditButton, SimpleList } from 'react-admin';

const UserList = () => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <List>
      {
        isSmall ? (
          <SimpleList
            primaryText={(record) => record.display_name}
            secondaryText={(record) => record.email}
          />
        ) : (
          <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="display_name" />
            <EmailField source="email" />
            <TextField source="serial_code" />
            <EditButton />
          </Datagrid>
        )
      }
    </List>
  )
};

export default UserList;
