import React from 'react';

const AdminDashboard: React.FC = () => (
  <div>
    <h1>管理画面</h1>
    <p>ようこそ</p>
  </div>
);

export default AdminDashboard;
