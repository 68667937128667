import {
  Edit,
  SimpleForm,
  TextInput,
  FileInput,
  FileField,
  ReferenceInput,
  useRecordContext,
  NumberInput,
} from 'react-admin';

const MeditationTitle = () => {
  const record = useRecordContext();
  return <span>瞑想編集: {record ? `"${record.title}"` : ''} </span>;
};

const MeditationEdit = () => {
  return (
    <Edit title={<MeditationTitle />}>
      <SimpleForm>
        <TextInput source="id" disabled />
        <ReferenceInput source="user_id" reference="users" label="User" />
        <TextInput source="title" />
        <TextInput source="script" multiline rows={20} />
        <FileInput source="image" label="画像">
          <FileField source="src" title="title" target="image/*" />
        </FileInput>
        <FileInput source="audio" label="音声">
          <FileField source="src" title="title" target="audio/*" />
        </FileInput>
        <NumberInput source="number_of_plays" label="再生回数" />
      </SimpleForm>
    </Edit>
  );
};

export default MeditationEdit;
