import {
  Show,
  TextField,
  EmailField,
  SimpleShowLayout,
  useRecordContext
} from 'react-admin';

const UserTitle = () => {
  const record = useRecordContext();
  return <span>ユーザー: {record ? `${record.display_name}` : ''}</span>;
}

const UserShow = () => (
  <Show title={<UserTitle />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="display_name" />
      <EmailField source="email" />
      <TextField source="serial_code" />
    </SimpleShowLayout>
  </Show>
);

export default UserShow;
