import React from 'react';
import { AppBar as DefaultAppBar, AppBarProps } from 'react-admin';

export const AppBar: React.FC<AppBarProps> = (props) => {
  const appBarColor = process.env.REACT_APP_ENVIRONMENT === 'staging' ? 'orange' : 'primary';
  console.log('env', process.env.REACT_APP_ENVIRONMENT)

  return (
    <DefaultAppBar
      {...props}
      sx={{
        bgColor: appBarColor,
        ...props.sx,
      }}
    />
  );
};
