import { useMediaQuery, Theme } from "@mui/material";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  SimpleList
} from 'react-admin';

const MeditationList = () => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <List>
      {
        isSmall ? (
          <SimpleList
            primaryText={(record) => record.user}
            secondaryText={(record) => record.title}
            tertiaryText={(record) => record.id}
          />
        ) : (
          <Datagrid>
            <TextField source="id" />
            <ReferenceField source="user_id" reference="users" link="show">
              <TextField source="display_name" />
            </ReferenceField>
            <TextField source="title" />
            <EditButton />
          </Datagrid>
        )
      }
    </List>
    )
};

export default MeditationList;
