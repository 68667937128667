import {
  Show,
  TextField,
  EditButton,
  SimpleShowLayout,
  useRecordContext,
  ReferenceField,
  NumberField,
} from 'react-admin';

const MeditationTitle = () => {
  const record = useRecordContext();
  return <span>瞑想: {record ? `"${record.title}"` : ''} ({record?.user})</span>;
};

const MeditationShow = () => {
  return (
    <Show title={<MeditationTitle />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField
          source="user_id"
          reference="users"
          link="show"
          label="User"
        />
        <TextField source="title" />
        <TextField source="script" />
        <ImageField source="image_url" />
        <AudioField source="audio_url" />
        <NumberField source="number_of_plays" label="再生回数" />
        <EditButton />
      </SimpleShowLayout>
    </Show>
  );
};

interface MediaFieldProps {
  source: string;
}

const ImageField = ({ source }: MediaFieldProps) => {
  const record = useRecordContext();
  return record && record[source] ? (
    <div>
      <img src={record[source]} alt={record.title} style={{ maxWidth: '100%' }} />
    </div>
  ) : null;
};

const AudioField = ({ source }: MediaFieldProps) => {
  const record = useRecordContext();
  return record && record[source] ? (
    <div>
      <audio controls>
        <source src={record[source]} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  ) : null;
};

export default MeditationShow;
