import UserIcon from '@mui/icons-material/Person2Rounded';

import UserShow from './UserShow';
import UserList from './UserList';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit';

interface User {
  id: string;
  display_name: string;
  email: string;
}

const resource = {
  show: UserShow,
  list: UserList,
  edit: UserEdit,
  create: UserCreate,
  icon: UserIcon,
  recordRepresentation: (record: User) => record.display_name
};

export default resource;
