import {
  Create,
  FileInput,
  FileField,
  ReferenceInput,
  SimpleForm,
  TextInput,
  NumberInput,
  useDataProvider,
  useNotify,
  useRedirect,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';

interface Meditation {
  user_id: string;
  title: string;
  script: string;
  image: { rawFile: File };
  audio: { rawFile: File };
}

const MeditationCreate = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const save = async (values: FieldValues) => {
    const { image, audio, ...rest } = values as Meditation;

    try {
      await dataProvider.create('meditations', { data: { ...rest, image, audio } });
      notify('Meditation created successfully');
      redirect('list', 'meditations');
    } catch (error) {
      const errorMessage = (error as Error).message || 'Unknown error';
      notify(`Error: ${errorMessage}`, { type: 'error' });
    }
  };

  return (
    <Create>
      <SimpleForm onSubmit={save}>
        <ReferenceInput
          source="user_id"
          reference="users"
          label="User"
        />
        <TextInput source="title" />
        <TextInput source="script" multiline rows={20} />
        <FileInput source="image" label="画像" >
          <FileField source="src" title="title" target="image/*" />
        </FileInput>
        <FileInput source="audio" label="音声" >
          <FileField source="src" title="title" target="audio/*" />
        </FileInput>
        <NumberInput source="number_of_plays" label="再生回数" defaultValue={0} />
      </SimpleForm>
    </Create>
  );
};

export default MeditationCreate;
