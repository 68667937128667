import { DataProvider, fetchUtils } from "react-admin";
import { stringify } from "query-string";

import { auth } from "./firebaseConfig";

const apiUrl = process.env.REACT_APP_API_URL;
const httpClient = async (url: string, options: fetchUtils.Options = {}) => {
  const user = auth.currentUser;
  if (user) {
    const token = await user.getIdToken();
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
    } else if (!(options.headers instanceof Headers)) {
      options.headers = new Headers(options.headers);
    }
    options.headers.append('Authorization', `Bearer ${token}`);
  }
  return fetchUtils.fetchJson(url, options);
};

const dataProvider: DataProvider = {
  getList: (resource, params) => {
    let pagination = params.pagination;
    if (!pagination) {
      pagination = { page: 1, perPage: 25 };
    }
    let { page, perPage } = pagination;

    let sort = params.sort;
    if (!sort) {
      sort = { field: 'id', order: 'ASC' };
    }
    let field = sort.field;
    let order = sort.order;

    const query = {
      limit: perPage,
      offset: (page - 1) * perPage,
      order_by: `${field} ${order}`,
    };

    const url = `${apiUrl}/admin/v1/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt((headers.get("content-range") || '0').split("/").pop() || '0', 10),
    }))
  },

  getOne: async (resource, params) => {
    const response = await httpClient(`${apiUrl}/admin/v1/${resource}/${params.id}`);
    const data = response.json;

    if (resource === "meditations") {
      const audioResponse = await httpClient(`${apiUrl}/admin/v1/${resource}/${params.id}/audio`);
      const imageResponse = await httpClient(`${apiUrl}/admin/v1/${resource}/${params.id}/image`);

      data.audio_url = audioResponse.json.url;
      data.image_url = imageResponse.json.url;
    }

    return { data };
  },

  getMany: (resource, _params) => {
    const url = `${apiUrl}/admin/v1/${resource}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, _params) => {
    const url = `${apiUrl}/admin/v1/${resource}`;
    return httpClient(url).then(({ headers, json }) => ({
        data: json,
        total: parseInt((headers.get('content-range') || "0").split('/').pop() || '0', 10),
    }));
  },

  update: async (resource, params) => {
    if (resource === 'meditations') {
      const { image, audio, ...rest } = params.data;

      const [imageResponse, audioResponse] = await Promise.all([
        image
          ? httpClient(`${apiUrl}/admin/v1/users/${rest.user_id}/image`, {
              method: 'POST',
              body: JSON.stringify({ file_name: image.rawFile.name }),
            }).then(({ json }) => {
              const uploadUrl = json.url;
              return fetch(uploadUrl, {
                method: 'PUT',
                body: image.rawFile,
                headers: { 'Content-Type': image.rawFile.type },
              }).then(() => json.file_path);
            })
          : Promise.resolve(rest.image_path),
        audio
          ? httpClient(`${apiUrl}/admin/v1/users/${rest.user_id}/audio`, {
              method: 'POST',
              body: JSON.stringify({ file_name: audio.rawFile.name }),
            }).then(({ json }) => {
              const uploadUrl = json.url;
              return fetch(uploadUrl, {
                method: 'PUT',
                body: audio.rawFile,
                headers: { 'Content-Type': audio.rawFile.type },
              }).then(() => json.file_path);
            })
          : Promise.resolve(rest.audio_path),
      ]);

      rest.image_path = imageResponse;
      rest.audio_path = audioResponse;

      return httpClient(`${apiUrl}/admin/v1/meditations/${params.id}`, {
        method: 'PATCH',
        body: JSON.stringify({ ...rest, previousData: params.previousData }),
      }).then(({ json }) => ({ data: json }));
    }

    return httpClient(`${apiUrl}/admin/v1/${resource}/${params.id}`, {
      method: 'PATCH',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids}),
    };
    return httpClient(`${apiUrl}/admin/v1/${resource}?${stringify(query)}`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: async (resource, params) => {
    if (resource === 'meditations') {
      const { image, audio, ...rest } = params.data;

      const [imageResponse, audioResponse] = await Promise.all([
        httpClient(`${apiUrl}/admin/v1/users/${rest.user_id}/image`, {
          method: 'POST',
          body: JSON.stringify({ file_name: image.rawFile.name }),
        }).then(({ json }) => {
          const uploadUrl = json.url;
          return fetch(uploadUrl, {
            method: 'PUT',
            body: image.rawFile,
            headers: { 'Content-Type': image.rawFile.type },
          }).then(() => json.file_path);
        }),
        httpClient(`${apiUrl}/admin/v1/users/${rest.user_id}/audio`, {
          method: 'POST',
          body: JSON.stringify({ file_name: audio.rawFile.name }),
        }).then(({ json }) => {
          const uploadUrl = json.url;
          return fetch(uploadUrl, {
            method: 'PUT',
            body: audio.rawFile,
            headers: { 'Content-Type': audio.rawFile.type },
          }).then(() => json.file_path);
        }),
      ]);

      rest.image_path = imageResponse;
      rest.audio_path = audioResponse;

      return httpClient(`${apiUrl}/admin/v1/meditations`, {
        method: 'POST',
        body: JSON.stringify(rest),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id } as any,
      }));
    }

    return httpClient(`${apiUrl}/admin/v1/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id } as any,
    }));
  },


  delete: (resource, params) => {
    return httpClient(`${apiUrl}/admin/v1/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }))
  },

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids}),
    };
    return httpClient(`${apiUrl}/admin/v1/${resource}?${stringify(query)}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }));
  },
};

export default dataProvider;
