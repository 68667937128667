import { AuthProvider } from 'react-admin';
import { signInWithEmailAndPassword, signOut, User } from 'firebase/auth';
import { auth } from './firebaseConfig';

const authProvider: AuthProvider = {
  // called when the user attempts to log in
  login: async ({ username, password }) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, username, password);
      sessionStorage.setItem('authUser', JSON.stringify(userCredential.user));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // called when the user clicks on the logout button
  logout: async () => {
    try {
      await signOut(auth);
      sessionStorage.removeItem('authUser');
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // called when the API returns an error
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      sessionStorage.removeItem('authUser');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    const user = sessionStorage.getItem('authUser');
    return user ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
  getIdentity: () => {
    const userJSON = sessionStorage.getItem('authUser');
    if (userJSON) {
      const user: User = JSON.parse(userJSON)
      return Promise.resolve({
        id: user.uid,
        fullName: user.displayName || '',
        avatar: user.photoURL || '',
      });
    }
    return Promise.resolve({
      id: '',
      fullName: '',
      avatar: '',
    });
  },
};

export default authProvider;
